<template>
  <div class="product-counter">
    <button
      class="product-counter__action"
      :disabled="!isEnableDecrement"
      @click="decrement"
    >
      -
    </button>

    <div>
      {{ counter }}
      <span v-if="isMaximumProvided">
        of
        {{ maximum }}
      </span>
    </div>

    <button
      :disabled="!isEnableIncrement"
      class="product-counter__action"
      @click="increment"
    >
      +
    </button>
  </div>
</template>

<script>
  export default {
    name: 'ProductCounterComponent',

    props: {
      value: {
        type: Number,
        default: 0,
      },
      minimum: {
        type: Number,
        default: 0,
      },
      maximum: {
        type: Number,
        default: undefined,
      },
    },

    data() {
      return {
        counter: 0,
      }
    },

    computed: {
      isMaximumProvided() {
        return this.maximum !== undefined;
      },
      isEnableDecrement() {
        return this.counter > this.minimum;
      },
      isEnableIncrement() {
        if (!this.isMaximumProvided) {
          return true;
        }

        return this.counter < this.maximum;
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(v) {
          this.counter = v;
        },
      },
    },

    methods: {
      decrement() {
        if (!this.isEnableDecrement) {
          return;
        }

        this.counter = this.counter - 1;
        this.onChangeCounter();
      },
      increment() {
        if (!this.isEnableIncrement) {
          return;
        }

        this.counter = this.counter + 1;
        this.onChangeCounter();
      },

      onChangeCounter() {
        this.$emit('input', this.counter);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product-counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 2px;
    border: 1px solid #edf1f4;
    font-size: 15px;

    @include lg() {
      padding: 5px;
      font-size: 16px;
    }

    button {
      height: 100%;
      font-weight: 500;
      border-radius: 3px;
      width: 18px;
      font-size: 14px;
      outline: none;

      &:hover {
        background-color: #edf1f4;
      }
      &:hover:disabled {
        cursor: not-allowed;
        background-color: white;
      }

      @include lg() {
        font-size: 20px;
        width: 21px;
      }
    }
  }
</style>
