var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.disable),expression:"disable"}],staticClass:"select-with-search"},[(_vm.label)?_c('div',{staticClass:"select-with-search__label"},[_c('label',[_vm._v(_vm._s(_vm.label))])]):_vm._e(),_c('div',{staticClass:"select-with-search__select",on:{"click":function($event){return _vm.toggle()}}},[_c('span',{staticClass:"select-with-search__value",class:{ 'select-with-search__value--selected': _vm.selectedLabel }},[_vm._v(" "+_vm._s(_vm.selectedLabel || _vm.placeholder)+" ")]),_c('i',{class:{
        'select-with-search__select__arrow': true,
        'fas': true,
        'fa-chevron-down': !_vm.visible,
        'fa-chevron-up': _vm.visible
      }})]),(_vm.visible)?_c('div',{staticClass:"select-with-search__options",class:{ hidden : !_vm.visible, visible: _vm.visible },on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.disable($event)}}},[(_vm.options.length)?_c('ul',{staticClass:"select-with-search__list"},_vm._l((_vm.options),function(item){return _c('li',{staticClass:"select-with-search__list-item",class:{
          'select-with-search__list-item': true,
          'select-with-search__list-item__active': _vm.value === item.value,
          'select-with-search__list-item__multiple': _vm.multiple,
        },on:{"click":function($event){return _vm.select(item)}}},[(_vm.multiple)?_c('form-checkbox-component',{attrs:{"value":_vm.isSelected(item)}}):_vm._e(),_vm._v(" "+_vm._s(item.label)+" ")],1)}),0):_c('div',{staticClass:"select-with-search__empty-list"},[_vm._v(" "+_vm._s(_vm.placeholder)+" not found ")])]):_vm._e(),_c('div',{staticClass:"sp-form-select-error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }