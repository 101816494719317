<template>
  <input
    v-model="input"
    type="checkbox"
    class="form-checkbox"
  />
</template>

<script>
  export default {
    name: 'FormCheckboxComponent',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      input: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
  };
</script>

<style lang="scss">
  .form-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    accent-color: #21A66E;

    &:hover, &:focus {
      accent-color: #21A66E;
    }
    input.custom-control-input {

    }
  }
</style>
