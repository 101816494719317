const reasons = [
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
  {
    label: 'Color',
    value: 'COLOR',
  },
  {
    label: 'Damaged or defective',
    value: 'DEFECTIVE',
  },
  {
    label: 'Item not as described',
    value: 'NOT_AS_DESCRIBED',
  },
  {
    label: 'Size was too small',
    value: 'SIZE_TOO_SMALL',
  },
  {
    label: 'Size was too large',
    value: 'SIZE_TOO_LARGE',
  },
  {
    label: 'Style',
    value: 'STYLE',
  },
  {
    label: 'Changed mind',
    value: 'UNWANTED',
  },
  {
    label: 'Received the wrong item',
    value: 'WRONG_ITEM',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export default reasons;
