<template>
  <div>
    <div>
      <label>{{ label }}</label>
    </div>
    <select
      v-model="selected"
      :class="{
        'sp-form-select-item': true,
        'sp-form-select-item__selected-placeholder': value === '',
      }"
    >
      <option
        v-if="placeholder"
        value=""
        class="sp-form-select-item__placeholder"
        disabled
        selected
        hidden
      >
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        class="sp-form-select-item__option"
      >
        {{ option.label }}
      </option>
    </select>
    <div class="sp-form-select-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormSelectComponent',

    props: {
      value: {
        type: String,
        default: '',
      },
      options: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      errorMessage: {
        type: String,
        default: '',
      },
    },

    computed: {
      selected: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-form-select-error {
    font-size: 12px;
    color: red;
  }
  .sp-form-select-item {
    width: 100%;
    border: 1px solid #EDF1F4;
    border-radius: 7px;
    padding: 8px 16px;
    font-size: 15px;
    -webkit-appearance: none;
    appearance: none;

    &:focus, &:hover {
      border: 1px solid #EDF1F4 !important;
      cursor: pointer;
      outline: none;
    }

    &__option {
      color: black;
    }

    &__selected-placeholder {
      color: #B6C0D3;
    }

    &__placeholder {
      color: #B6C0D3;
    }
  }
</style>
