<template>
  <div class="request-return">
    <div class="request-return__header">
      <div
        class="request-return__header__back"
        @click="goToOrder"
      >
        <i class="fas fa-chevron-left"/>
      </div>
      <div>
        Request return
      </div>
    </div>

    <div class="request-return__wrap">
      <div class="request-return__list">
        <loader
          v-if="isOrderLoading"
          style="width: 100%;"
          color="#343434"
          position="relative"
          class="mt-5"
        />
        <request-return-fulfillment-item-component
          v-for="(item, i) in potentialItemsToReturn"
          :key="i"
          ref="requestReturnFulfillmentItems"
          class="request-return__list__item"
          :form-errors="validationErrors[item.fulfillmentLineItem.id]"
          :fulfillment-item="item"
          :returnable-line-item="item"
          @item:update-request-reason="updateReason"
          @item:change-requested-count="updateRequestedCount"
        />

        <div
          v-if="nonReturnableItems.length > 0"
          class="request-return__list__sub-title"
        >
          {{ nonReturnableItems.length }} items in this order isn`t eligible for return
        </div>
        <request-return-fulfillment-item-component
          v-for="item in nonReturnableItems"
          :key="item.fulfillmentLineItemId"
          :is-able-to-return="false"
          class="request-return__list__item"
          :form-errors="validationErrors[item.fulfillmentLineItemId]"
          :returnable-line-item="item"
        />
      </div>

      <div class="request-return__actions">
        <div class="request-return__actions__heading">
          Returning
        </div>
        <div>
          <div class="request-return__actions__count">
            <div>
              Return items
            </div>
            <div>
              {{ totalReturnableItems }} Items
            </div>
          </div>
          <div>
            <button-component
              label="Request Return"
              size="large"
              :loading="isFormSubmitted"
              :disabled="isSubmitButtonDisabled"
              @click="onCreateRequest"
            />
          </div>
          <div
            v-if="submitErrorMessage"
            class="sp-submit-error-message"
          >
            {{ submitErrorMessage }}
          </div>
          <div class="request-return__actions__warning">
            <div class="request-return__actions__warning__icon">
              <i class="far fa-exclamation-circle"/>
            </div>
            <div>
              Return and refund eligibility will be based on our refund policy.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OrderApi from '@/entities/account/features/order/api';
  import RequestReturnFulfillmentItemComponent
    from '@/entities/account/features/request-return/components/request-return-fullfillment-item.component';
  import FormSelectComponent from '@/shared/components/form-components/form-select.component';
  import ButtonComponent from '@/shared/components/button.component.vue';
  import Loader from '@/components/loader/loader.vue';
  import RequestReturnApi from './api/index';
  import Auth from '@/service/authService';

  export default {
    name: 'RequestReturnContainer',
    components: { Loader, ButtonComponent, FormSelectComponent, RequestReturnFulfillmentItemComponent },

    data() {
      return {
        isOrderLoading: false,
        isFormSubmitted: false,
        order: null,
        returnableLineItems: [],
        validationErrors: {},
        submitErrorMessage: '',
      }
    },

    computed: {
      currentUser() {
        return Auth.get('user');
      },
      orderId() {
        return this.$route.params.id;
      },
      potentialItemsToReturn() {
        return this.returnableLineItems.filter((item) => item.fulfillmentLineItem.isReturnable);
      },
      nonReturnableItems() {
        return this.returnableLineItems.filter(f => !f.fulfillmentLineItem.isReturnable);
      },
      itemsToReturn() {
        return this.returnableLineItems.filter((item) => !!item.amountItemsToReturn)
      },
      totalReturnableItems() {
        return this.itemsToReturn.reduce((acc, item) => acc + item.amountItemsToReturn, 0);
      },
      isSubmitButtonDisabled() {
        return !this.isValidForm || this.isOrderLoading || this.isFormSubmitted || this.itemsToReturn.length < 1;
      },
      isValidForm() {
        return Object.keys(this.validationErrors).length < 1;
      },
    },

    beforeMount() {
      this.loadLoadItemsForReturning();
    },

    methods: {
      async onCreateRequest() {
        if (this.itemsToReturn.length < 1) {
          return;
        }
        const errors = this.validateRequest();
        if (Object.keys(errors).length > 0) {
          this.validationErrors = errors;
          setTimeout(() => {
            this.scrollToFirstInvalidForm();
          }, 100);
          return;
        }

        try {
          this.isFormSubmitted = true;
          const res = await OrderApi.requestReturn({
            orderId: `gid://shopify/Order/${this.orderId}`,
            returnLineItems: this.returnableLineItems.filter((i) => !!i.amountItemsToReturn).map((item) =>({
              customerNote: item.notesAboutRequest,
              returnReason: item.reasonToRequest,
              quantity: item.amountItemsToReturn,
              fulfillmentLineItemId: item.fulfillmentLineItem.id,
            })),
          });
          if (res?.returnRequest?.userErrors.length > 0) {
            this.submitErrorMessage = 'Something went wrong';
            throw new Error('Something went wrong');
          }
          this.goToOrder();
        } catch (err) {
          console.log('err', err);
        } finally {
          this.isFormSubmitted = false;
        }
      },
      goToOrder() {
        this.$router.push({ name: 'order-id', params: { id: this.orderId }})
      },
      scrollToFirstInvalidForm() {
        let alreadyScrolled = false;
        const refs = this.$refs.requestReturnFulfillmentItems || [];
        refs.forEach((ref, i) => {
          if (alreadyScrolled || !ref.$el) {
            return;
          }

          if (!ref.hasErrors) {
            return;
          }

          this.$refs.requestReturnFulfillmentItems[i].$el.scrollIntoView(false);
          alreadyScrolled = true;
        });
      },
      validateRequest() {
        const formErrors = {};
        this.returnableLineItems.forEach((item, i) => {
          const errors = this.validateFulfillmentLineItem(item);
          if (errors) {
            formErrors[item.fulfillmentLineItem.id] = errors;
          }
        });

        return formErrors;
      },
      validateFulfillmentLineItem(item) {
        if (!item.amountItemsToReturn) {
          return;
        }

        const itemErrors = {};
        if (!item.reasonToRequest) {
          itemErrors.reasonToRequest = 'Reason is required.';
        }

        if (item.notesAboutRequest && item.notesAboutRequest.length > 300) {
          itemErrors.notesAboutRequest = 'Maximum length is 300 symbols';
        }

        return Object.keys(itemErrors).length > 0 ? itemErrors : null;
      },
      resetErrorsFor(id) {
        this.submitErrorMessage = '';
        const { [id]: _, ...validationErrors } = this.validationErrors;
        this.validationErrors = validationErrors;
      },
      async loadLoadItemsForReturning() {
        try {
          this.isOrderLoading = true;
          const belongs = await this.checkIfUserBelongsToOrder();
          if (!belongs) {
            return this.$router.push({ name: 'orders' });
          }
          const items = await RequestReturnApi.getReturnableFulfillments(this.orderId);
          if (items.length < 1) {
            return this.goToOrder();
          }

          this.returnableLineItems = this.groupByItems(items);
        } catch (err) {
          console.log('err', err);
        } finally {
          this.isOrderLoading = false;
        }
      },
      async checkIfUserBelongsToOrder() {
        try {
          const order = await OrderApi.getNormalizedOrder(this.orderId);
          return this.currentUser.privateId === order.customer.email
        } catch (err) {
          return false;
        }
      },
      groupByItems(returnableFulfillments) {
        return returnableFulfillments.map((rf) => rf.items).flat();
      },
      updateReason({ fulfillmentItem, reasonToRequest, notesAboutRequest, notes }) {
        this.updateReturnableLineItem({
          fulfillmentItem,
          body: { reasonToRequest, notesAboutRequest },
        });
        this.resetErrorsFor(fulfillmentItem.id);
        this.validationErrors = this.validateRequest();
      },
      updateRequestedCount({ fulfillmentItem, amountItemsToReturn }) {
        this.updateReturnableLineItem({ fulfillmentItem, body: { amountItemsToReturn }});
        this.validationErrors = this.validateRequest();
      },
      updateReturnableLineItem({ fulfillmentItem, body }) {
        this.returnableLineItems = this.returnableLineItems.map((fi) => {
          if (fi.fulfillmentLineItem.id !== fulfillmentItem.id) {
            return fi;
          }

          return {
            ...fi,
            ...body,
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-submit-error-message {
    font-size: 12px;
    color: red;
  }
  .request-return {
    &__header {
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      &__back {
        padding-right: 10px;
        cursor: pointer;
        font-size: 16px;
      }
    }

    &__list {
      width: 100%;
      &__item {
        margin-bottom: 14px;
      }

      &__sub-title {
        margin-top: 20px;
        margin-bottom: 5px;
        color: #343434;
        font-size: 18px;
      }
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
      column-gap: 30px;
    }

    &__actions {
      width: 70%;
      max-width: 400px;
      padding: 15px;
      border: 1px solid #EDF1F4;
      border-radius: 5px;
      height: max-content;
      &__heading {
        font-weight: 500;
        font-size: 20px;

        margin: 0 -15px 15px -15px;
        padding: 0 15px 15px 15px;
        border-bottom: 1px solid #EDF1F4;
      }

      &__count {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        margin-bottom: 15px;
      }

      &__warning {
        margin-top: 15px;
        background-color: #F0F2F6;
        border-radius: 5px;
        font-size: 15px;
        display: flex;
        align-items: flex-start;
        padding: 15px;

        &__icon {
          font-size: 18px;
          margin-right: 15px;
        }
      }
    }

  }
</style>
