<template>
  <div>
    <div v-if="label">
      <label>{{ label }}</label>
    </div>
    <textarea
      v-model="input"
      class="sp-form-textarea"
      :placeholder="placeholder"
    />
    <div class="sp-form-textarea-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormTextareaComponent',

    props: {
      value: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      errorMessage: {
        type: String,
        default: '',
      },
    },

    computed: {
      input: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', (val || '').trim());
        },
      },

    },
  };
</script>

<style lang="scss" scoped>
  .sp-form-textarea {
    border: 1px solid #ECF0F6;
    border-radius: 5px;
    width: 100%;
    padding: 8px 16px;
    font-size: 15px;
    &::placeholder {
      color: #B6C0D3;
    }

    &:focus {
      outline: none;
    }
  }

  .sp-form-textarea-error {
    font-size: 12px;
    color: red;
  }
</style>
