<template>
  <div class="request-return-fulfillment-item">
    <div class="request-return-fulfillment-item__main">
      <div class="request-return-fulfillment-item__main__product">
        <div class="request-return-fulfillment-item__main__product__image">
          <img
            :src="productImageSrc"
          />
        </div>
        <div>
          <div class="request-return-fulfillment-item__main__product__title">
            {{ product.title }}
          </div>
          <div class="request-return-fulfillment-item__main__product__description">
            {{ fulfillmentItem.lineItem.variantTitle }}
          </div>
          <div class="request-return-fulfillment-item__main__product__price">
            {{ productPricePerUnit }}
          </div>
        </div>
      </div>

      <div v-if="isAbleToReturn">
        <product-counter-component
          :value="returnableLineItem.amountItemsToReturn"
          :minimum="0"
          :maximum="returnableLineItem.quantity"
          @input="onChangeRequestedCount"
        />
      </div>

      <div
        v-if="isAbleToReturn"
        class="request-return-fulfillment-item__main__total"
      >
        {{ totalForLineItem }}
      </div>
    </div>
    <div
      v-if="isAbleToReturn"
      class="request-return-fulfillment-item__divider"
    />

    <div
      v-if="isAbleToReturn"
      class="request-return-fulfillment-item__reason-select"
    >
      <form-dropdown-component
        :value="returnableLineItem.reasonToRequest"
        label="Reason"
        :error-message="formErrors.reasonToRequest"
        :options="returnReasonOptions"
        placeholder="Select a reason"
        @input="updateReason"
      />
    </div>
    <div
      v-if="isAbleToReturn"
    >
      <form-textarea-component
        :value="returnableLineItem.notesAboutRequest"
        :error-message="formErrors.notesAboutRequest"
        placeholder="Notes (Optional)"
        @input="updateNotes"
      />
    </div>
  </div>
</template>

<script>
  import FormCheckboxComponent from '@/shared/components/form-components/form-checkbox.component';
  import ProductCounterComponent from '@/shared/components/product-counter.component.vue';
  import FormSelectComponent from '@/shared/components/form-components/form-select.component.vue';
  import reasons from '@/store/constants/return-reasons.constants';
  import FormTextareaComponent from '@/shared/components/form-components/form-textarea.component.vue';
  import FormDropdownComponent from '@/shared/components/form-components/form-dropdown.component.vue';

  export default {
    name: 'RequestReturnProductComponent',
    components: { FormDropdownComponent, FormTextareaComponent, FormSelectComponent, ProductCounterComponent, FormCheckboxComponent },

    props: {
      returnableLineItem: {
        type: Object,
        default: () => ({}),
      },
      formErrors: {
        type: Object,
        default: () => ({}),
      },
      isAbleToReturn: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        returnReasonOptions: reasons,
      }
    },

    computed: {
      fulfillmentItem() {
        return this.returnableLineItem.fulfillmentLineItem;
      },
      hasErrors() {
        return Object.keys(this.formErrors).length > 0;
      },
      product() {
        return this.fulfillmentItem.lineItem.product;
      },
      productImageSrc() {
        return this.fulfillmentItem.lineItem.image.url;
      },
      totalForLineItem() {
        return this.formatPrice(this.pricePerUnit * (this.returnableLineItem.amountItemsToReturn || 0));
      },
      pricePerUnit() {
        return this.fulfillmentItem.lineItem.discountedUnitPriceSet.shopMoney.amount;
      },
      productPricePerUnit() {
        return this.formatPrice(this.pricePerUnit);
      },
    },

    methods: {
      formatPrice(price) {
        return `$${(Number(price)).toFixed(2)}`
      },
      updateReason(reason) {
        this.$emit('item:update-request-reason', {
          fulfillmentItem: this.fulfillmentItem,
          reasonToRequest: reason,
          notesAboutRequest: this.returnableLineItem.notesAboutRequest,
        })
      },
      updateNotes(notes) {
        this.$emit('item:update-request-reason', {
          fulfillmentItem: this.fulfillmentItem,
          reasonToRequest: this.returnableLineItem.reasonToRequest,
          notesAboutRequest: notes,
          triggerValidationField: 'notes',
        })
      },
      onChangeRequestedCount(count) {
        this.$emit('item:change-requested-count', {
          fulfillmentItem: this.fulfillmentItem,
          amountItemsToReturn: count,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .request-return-fulfillment-item {
    border-radius: 5px;
    border: 1px solid #EDF1F4;
    padding: 10px 25px 10px 15px;

    &__divider {
      border-top: 1px solid #EDF1F4;
      margin: 1rem -15px;
    }

    &__reason-select {
      margin-bottom: 15px;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__select {
        width: 1.8rem;
      }

      &__total {
        width: 9rem;
        text-align: right;
      }

      &__product {
        flex: 1;
        display: flex;
        align-items: center;
        &__image {
          margin-right: 1rem;
          img {
            width: 4rem;
            height: 100%;
            border-radius: 5px;
          }
        }

        &__title {
          font-weight: 500;
          color: #343434;
          font-size: 16px;
        }

        &__description {
          color: #ACB6CA;
          font-size: 15px;
        }

        &__price {
          font-size: 16px;
          color: #343434;
        }
      }
    }
  }
</style>
