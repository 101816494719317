<template>
  <request-return-container/>
</template>

<script>
  import RequestReturnContainer from './request-return.container';
  export default {
    name: 'RequestReturnView',

    components: { RequestReturnContainer },
  };
</script>

<style lang="scss" scoped>

</style>
