<template>
  <div
    class="select-with-search"
    v-click-outside="disable"
  >
    <div
      v-if="label"
      class="select-with-search__label"
    >
      <label>{{ label }}</label>
    </div>
    <div
      class="select-with-search__select"
      @click="toggle()"
    >
      <span class="select-with-search__value" :class="{ 'select-with-search__value--selected': selectedLabel }">
        {{ selectedLabel || placeholder }}
      </span>
      <i
        :class="{
          'select-with-search__select__arrow': true,
          'fas': true,
          'fa-chevron-down': !visible,
          'fa-chevron-up': visible
        }"
      />
    </div>

    <div
      v-if="visible"
      class="select-with-search__options"
      :class="{ hidden : !visible, visible }"

      @keydown.esc="disable"
    >
      <ul v-if="options.length" class="select-with-search__list">
        <li
          v-for="item in options"
          class="select-with-search__list-item"
          :class="{
            'select-with-search__list-item': true,
            'select-with-search__list-item__active': value === item.value,
            'select-with-search__list-item__multiple': multiple,
          }"
          @click="select(item)"
        >
          <form-checkbox-component
            v-if="multiple"
            :value="isSelected(item)"
          />
          {{ item.label }}
        </li>
      </ul>

      <div v-else class="select-with-search__empty-list">
        {{ placeholder }} not found
      </div>
    </div>
    <div class="sp-form-select-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  import FormCheckboxComponent from '@/shared/components/form-components/form-checkbox.component.vue';

  export default {
    name: 'FormDropdownComponent',
    components: { FormCheckboxComponent },
    props: {
      placeholder: {
        type: String,
        default: 'Select option',
      },
      label: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Array],
        default: '',
      },
      options: {
        type: Array,
        default: () => [],
      },
      errorMessage: {
        type: String,
        default: '',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        visible: false,
        searchString: '',
      }
    },
    computed: {
      selectedLabel() {
        if (this.multiple) {
          const titles = this.value.reduce((acc, value) => {
            const item = this.options.find((it) => it.value === value);
            acc.push(item.label);
            return acc;
          }, []);
          return titles.join(', ');
        }
        return this.options.find((item) => item.value === this.value)?.label
      },
      isSelected() {
        return (item) => !!this.value.includes(item.value);
      },
    },
    methods: {
      disable() {
        this.visible = false;
      },
      toggle() {
        this.visible = !this.visible;
      },
      select(option) {
        if (!this.multiple) {
          this.$emit('input', option.value);
          return this.toggle();
        }

        const newArray = this.value.filter((item) => item !== option.value);
        if (newArray.length === this.value.length) {
          newArray.push(option.value);
        }
        this.$emit('input', newArray);
        this.$emit('select:item', option);

      },
    },
  }
</script>

<style scoped lang="scss">
  .sp-form-select-error {
    font-size: 12px;
    color: red;
  }
  .select-with-search {
    position: relative;
    &__label {

    }

    &__select {
      cursor: pointer;
      border: 1px solid #EDF1F4;
      border-radius: 7px;
      //padding: 8px 16px;
      padding: 8px 25px 8px 16px;
      font-size: 15px;
      -webkit-appearance: none;
      appearance: none;
      position: relative;
      &__arrow {
        right: 10px;
        bottom: 30%;
        position: absolute;
      }
    }

    &__value {
      color: #B6C0D3;


      &--selected {
        color: black;
      }
    }

    &__options {
      display: none;
      width: 100%;
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      border: 1px solid #e7ebf1;
      border-radius: 5px;
      background: #fff;
      overflow-y: auto;
      max-height: 250px;
      z-index: 100;

      &.visible {
        display: block;
      }
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__list-item {
      padding: 12px 16px;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: #343434;
      transition: .3s ease all;
      cursor: pointer;

      &__active {
        background: #f2f4f9;
      }

      &__multiple {
        display: flex;
        align-items: center;
        input {
          margin-right: 5px;
        }
      }

      &__icon {
        visibility: hidden;
        color: black;
        margin-right: 3px;
        &__active {
          visibility: visible;
        }
      }

      &:hover {
        background: #f2f4f9;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e7ebf1;
      }
    }

    &__empty-list {
      padding: 8px 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #343434;
    }
  }
</style>